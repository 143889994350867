exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-chirurgia-stomatologiczna-js": () => import("./../../../src/pages/chirurgia-stomatologiczna.js" /* webpackChunkName: "component---src-pages-chirurgia-stomatologiczna-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leczenie-bolu-glowy-js": () => import("./../../../src/pages/leczenie-bolu-glowy.js" /* webpackChunkName: "component---src-pages-leczenie-bolu-glowy-js" */),
  "component---src-pages-mapka-dojazdu-js": () => import("./../../../src/pages/mapka-dojazdu.js" /* webpackChunkName: "component---src-pages-mapka-dojazdu-js" */),
  "component---src-pages-o-gabinecie-js": () => import("./../../../src/pages/o-gabinecie.js" /* webpackChunkName: "component---src-pages-o-gabinecie-js" */),
  "component---src-pages-ortodoncja-js": () => import("./../../../src/pages/ortodoncja.js" /* webpackChunkName: "component---src-pages-ortodoncja-js" */),
  "component---src-pages-parodontologia-higiena-jamy-ustnej-js": () => import("./../../../src/pages/parodontologia-higiena-jamy-ustnej.js" /* webpackChunkName: "component---src-pages-parodontologia-higiena-jamy-ustnej-js" */),
  "component---src-pages-profilaktyka-wczesne-leczenie-js": () => import("./../../../src/pages/profilaktyka-wczesne-leczenie.js" /* webpackChunkName: "component---src-pages-profilaktyka-wczesne-leczenie-js" */),
  "component---src-pages-protetyka-js": () => import("./../../../src/pages/protetyka.js" /* webpackChunkName: "component---src-pages-protetyka-js" */),
  "component---src-pages-stomatologia-zachowawcza-js": () => import("./../../../src/pages/stomatologia-zachowawcza.js" /* webpackChunkName: "component---src-pages-stomatologia-zachowawcza-js" */),
  "component---src-pages-wybielanie-zebow-js": () => import("./../../../src/pages/wybielanie-zebow.js" /* webpackChunkName: "component---src-pages-wybielanie-zebow-js" */)
}

